import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import cx from 'classnames';
import Banner from '@components/Banner';
import Footer from '@components/Footer';
import Logo from '../../assets/logo.inline.svg';
import * as styles from './Layout.module.scss';

const Layout = ({
  location,
  title,
  children,
  footerProps,
  nobackground,
  bannerProps,
}) => {
  const rootPath = `${process.env.GATSBY_HOST_DOMAIN}/`;
  const isRootPath = location.pathname === rootPath;
  let header;

  if (isRootPath) {
    header = (
      <h1 className="main-heading">
        <Link to="/">{title}</Link>
      </h1>
    );
  } else {
    header = (
      <Link className="header-link-home" to="/">
        {title}
      </Link>
    );
  }

  return (
    <React.Fragment>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
        <link href="https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,400;0,800;1,400;1,800&display=swap" rel="stylesheet" />
      </Helmet>
      {bannerProps && (
        <Banner
          html={bannerProps.bannerTextHtml}
          end={bannerProps.bannerEndTime}
          start={bannerProps.bannerStartTime}
        />
      )}
      <div
        className={cx(styles.layout, {
          [styles.layoutCream]: nobackground,
        })}
      >
        <div className={cx(styles.layout__content)} data-is-root-path={isRootPath}>
          <header className={cx(styles.layout__header)}>
            <div className={cx(styles.layout__logo)}>
              <Logo title="Nourish the Future" />
            </div>
          </header>
          <main>{children}</main>
          {footerProps && (
            <Footer {...footerProps} />
          )}
        </div>
        {!nobackground && (
          <React.Fragment>
            <div className={styles.layout__btarc} />
            <div className={styles.layout__btpattern} />
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default Layout;
