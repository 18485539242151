import React from 'react';
import * as styles from './MaxContent.module.scss';

const MaxContent = ({ children }) => (
  <div className={styles.maxcontent}>
    {children}
  </div>
);

export default MaxContent;
