import React from 'react';
import * as styles from './Footer.module.scss';

const Footer = ({
  links, credits, text,
}) => (
  <footer>
    <div className={styles.footer__max}>
      <div className={styles.footer__content}>
        {links && links.map((link) => {
          const { linkText, linkUrl } = link;
          return (
            <a className={styles.footer__link} href={linkUrl}>
              {linkText}
            </a>
          );
        })}
        {text && (
          <div
            className={styles.footer__credits}
              // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
        )}
        <div className={styles.footer__credits}>
          {credits && (
            <ul className={styles.footer__list}>
              {credits.map((credit) => (
                <li className={styles.footer__item}>{credit.credit}</li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
