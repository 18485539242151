import React from 'react';
import Gutters from '@components/Gutters';
import MaxContent from '@components/MaxContent';
import * as styles from './Banner.module.scss';

const Banner = ({ html, start, end }) => {
  const currentTime = Date.now();
  const startTime = start && parseInt(start, 10);
  const endTime = end && parseInt(end, 10);

  if (currentTime >= startTime && currentTime <= endTime && html) {
    return (
      <div className={styles.banner} role="banner">
        <MaxContent>
          <Gutters>
            {html && (
              <div
                className={styles.banner__text}
                  // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: html,
                }}
              />
            )}
          </Gutters>
        </MaxContent>
      </div>
    );
  }
  return (
    <React.Fragment />
  );
};

export default Banner;
