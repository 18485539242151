import * as React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const Seo = ({
  description, lang, meta, title, metaImage,
}) => {
  const { site } = useStaticQuery(
    graphql`
       query {
         site {
           siteMetadata {
             title
             description
             social {
               twitter
             }
           }
         }
       }
     `,
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata.title;
  const finalMetaImage = `https://${process.env.GATSBY_HOST_DOMAIN}${metaImage}`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title || defaultTitle}
      titleTemplate={title ? `%s | ${defaultTitle}` : defaultTitle}
    >
      {metaDescription && <meta name="description" content={metaDescription} />}
      {metaImage && <meta name="image" content={finalMetaImage} />}
      <meta name="og:title" content={title || defaultTitle} />
      {metaImage && <meta property="og:image" content={finalMetaImage} />}
      {metaDescription && <meta name="og:description" content={metaDescription} />}
      <meta property="og:type" content="website" />
      <meta name="twitter:title" content={title || defaultTitle} />
      {metaDescription && <meta name="twitter:description" content={metaDescription} />}
      <meta name="twitter:card" content="summary_large_image" />
      {metaImage && <meta name="twitter:image" content={finalMetaImage} />}
    </Helmet>
  );
};

Seo.defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default Seo;
