import React from 'react';
import * as styles from './Gutters.module.scss';

const Gutters = ({ children }) => (
  <div className={styles.gutters}>
    {children}
  </div>
);

export default Gutters;
